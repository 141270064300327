
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonCard, IonCardContent, IonToolbar, IonButton, IonRow, IonCol, IonCardHeader, IonCardTitle, loadingController } from '@ionic/vue';
import useFirebaseAuth from "@/hooks/firebase-auth";
import useUtil from "@/hooks/use-util";
import { useRouter} from "vue-router";
import { CONFIG_ROUTE_SCORES } from "@/hooks/config-service";

import firebase from "firebase";


export default {
  name: 'Login',
  components: {

    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonToolbar,
    IonButton,
    IonRow,
    IonCol,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardTitle,
  },

  setup() {
    const {  user, loginWithProvider, getPrimaryCompanyFromUserCompanyObj } = useFirebaseAuth();
    // const route = useRoute();
    const router = useRouter();
    const { setPageTitle, setFavicon } = useUtil();
    const timeout = 10000;
    let loading: any;

    setFavicon('');
    setPageTitle('Employee Portal');

    async function cancelLoading() {
      if (loading) {
        loading.dismiss();
      }
    }
    async function presentLoading() {
      loading = await loadingController
          .create({
            cssClass: 'my-custom-class',
            message: 'Initializing your account...',
            duration: timeout,
            backdropDismiss: false
          });

      await loading.present();
    }

    const doLogin = async () => {
      const myUser = await loginWithProvider();
      // console.log('Login.vue myUser', myUser);
      // console.log('Login.vue user', user.value);

      if (!user) {
        console.log('!user - not logged in');
        return;
      } else if (!user.value) {
        console.log('!user.value - not logged in');
        return;
      } else if (!user.value.uid) {
        console.log('!user.value.uid - not logged in');
        return;
      }

      await router.replace({ path: '/' + CONFIG_ROUTE_SCORES });



      // router.replace({ path: "/login" });
    };

    return { doLogin }
  }
}
